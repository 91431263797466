<!-- 订单列表 -->
<template>
	<div class="bg-white px-3" style="margin: -20px;margin-top: -1rem; margin-bottom: 0!important;">
		<!-- 头部标签页 -->
		<el-tabs v-model="tabIndex" @tab-click="handleClick">
			<el-tab-pane :label="tab.name" v-for="(tab,tabI) in tabbars" :key="tabI"></el-tab-pane>
		</el-tabs>

		<button-search ref="buttonSearch" placeholder="要搜索的订单编号" @search="searchEvent">
			<!-- 左边按钮 -->
			<template #left>
				<el-button type="success" size="mini" @click="exportModal = true">导出数据</el-button>
				<el-button type="danger" size="mini" @click="deleteAll">批量删除</el-button>
			</template>

			<!-- 高级搜索 表单 -->
			<template #form>
				<el-form inline ref="form" :model="form" label-width="80px">

					<!-- 订单编号 -->
					<el-form-item label="订单编号" class="mb-0">
						<el-input v-model="form.no" placeholder="订单编号" size="mini"></el-input>
					</el-form-item>

					<!-- 下单时间 -->
					<el-form-item label="下单时间" class="mb-0">
						<el-date-picker size="small" v-model="form.time" type="daterange" range-separator="至" start-placeholder="开始日期"
						 end-placeholder="结束日期" value-format="yyyy-MM-dd">
						</el-date-picker>
					</el-form-item>

					<!-- 收货人 -->
					<el-form-item label="收货人" class="mb-0">
						<el-input v-model="form.username" placeholder="收货人" size="mini"></el-input>
					</el-form-item>

					<!-- 手机号 -->
					<el-form-item label="手机号" class="mb-0">
						<el-input v-model="form.phone" placeholder="手机号" size="mini"></el-input>
					</el-form-item>

					<el-form-item>
						<el-button type="info" size="mini" @click="searchEvent">搜索</el-button>
						<el-button size="mini" @click="clearSearch">清空筛选条件</el-button>
					</el-form-item>
				</el-form>
			</template>
		</button-search>

		<!-- 商品列表 -->
		<el-table :data="tableData" style="width: 100%" border class="mt-3" @selection-change="handleSelectionChange" size="mini">
			<!-- 多选 -->
			<el-table-column type="selection" width="45" align="center">
			</el-table-column>

			<!-- 商品信息 -->
			<el-table-column label="商品信息" width="350">
				<!-- scope获得data里面tableData索引信息 -->
				<template slot-scope="scope">
					<div class="d-flex">
						<div style="flex: 1">
							<p class="mb-1">订单编号</p>
							<p class="mb-1"><small>{{scope.row.no}}</small></p>
						</div>
						<div style="flex: 1;">
							<p class="mb-1">下单时间：</p>
							<p class="mb-1">
								<small>{{scope.row.create_time}}</small>
							</p>
						</div>
					</div>
					<div class="media border-top py-2" >
						<img class="mr-3" style="width: 60px;height: 60px;" :src="scope.row.goods_item.cover">
						<div class="media-body">
							<p class="mt-0">
								<a class="text-primary">{{scope.row.goods_item.title}}</a>
							</p>
						</div>
					</div>
				</template>
			</el-table-column>

			<!-- 实付款 -->
			<el-table-column align="center" width="120" label="实付款">
				<template slot-scope="scope">
					<span>￥{{scope.row.price}}</span>
					<p><small>(含运费：￥0.00)</small></p>
				</template>
			</el-table-column>

			<!-- 买家 -->
			<el-table-column align="center" label="买家" width="120">
				<template slot-scope="scope">
					<span>{{scope.row.user.nickname}}</span>
					<p><small>(用户id：{{scope.row.user.id}})</small></p>
				</template>
			</el-table-column>

			<!-- 支付方式 -->
			<el-table-column prop="status" align="center" label="支付方式">
				<template slot-scope="scope">
					<span class="badge badge-success" v-if="scope.row.payment_method === 'wechat'">微信支付</span>
					<span class="badge badge-primary" v-else-if="scope.row.payment_method === 'alipay'">支付宝支付</span>
					<span class="badge badge-default" v-else>未支付</span>
				</template>
			</el-table-column>

			<!-- 配送方式 -->
			<el-table-column align="center" label="配送方式">
				<template slot-scope="scope">
					<div v-if="scope.row.ship_data">
						<div>{{scope.row.ship_data.express_company}}</div>
						<div>{{scope.row.ship_data.express_no}}</div>
					</div>
					<span class="badge badge-default" v-else>未配送</span>
				</template>
			</el-table-column>

			<!-- 交易状态 -->
			<el-table-column align="center" width="170" label="交易状态">
				<template slot-scope="scope">
					<div>付款状态:
						<span class="badge" :class="scope.row.payment_method ? 'badge-success' : 'badge-secondary'">{{scope.row.payment_method ? '已付款' : '未付款'}}</span>
					</div>
					<div>发货状态:
						<span class="badge" :class="scope.row.ship_data ? 'badge-success' : 'badge-secondary'">{{scope.row.ship_data ? '已发货' : '未发货'}}</span>
					</div>
					<div>收货状态:
						<span class="badge" :class="scope.row.ship_status === 'received' ? 'badge-success' : 'badge-secondary'">{{scope.row.ship_status === 'received' ? '已收货' : '未收货'}}</span>
					</div>
				</template>
			</el-table-column>

			<!-- 操作 -->
			<el-table-column align="center" label="操作" width="150">
				<template slot-scope="scope">
					<el-button type="text" size="mini" @click="openInfoModal(scope.row)">订单详情</el-button>
					<el-button type="text" size="mini" @click="ship(scope.row)" v-if="scope.row.ship_status ==='pending' && scope.row.closed === 0 && scope.row.payment_method && scope.row.refund_status === 'pending'">订单发货</el-button>
					<el-button type="text" size="mini" v-if="scope.row.ship_status ==='pending' && scope.row.closed === 0 && scope.row.payment_method && scope.row.refund_status === 'applied'"
					 @click="handleRefund(scope.row.id,1)">同意退款</el-button>
					<el-button type="text" size="mini" v-if="scope.row.ship_status ==='pending' && scope.row.closed === 0 && scope.row.payment_method && scope.row.refund_status === 'applied'"
					 @click="handleRefund(scope.row.id,0)">拒绝退款</el-button>
				</template>
			</el-table-column>
		</el-table>
		<!-- 底部内容 -->
		<div style="height: 60px;"></div>
		<el-footer class="border-top d-flex align-items-center px-0 position-fixed bg-white" style="bottom: 0; left: 200px; right: 0;z-index: 100;">
			<!-- 底部分页 -->
			<div style="flex: 1;" class="px-2">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page.current"
				 :page-sizes="page.sizes" :page-size="page.size" layout="total, sizes, prev, pager, next, jumper" :total="page.total">
				</el-pagination>
			</div>
		</el-footer>

		<!-- 订单发货 弹窗 -->
		<el-dialog title="订单发货" :visible.sync="shipModal" width="30%">
			<el-form :model="shipForm" ref="ruleForm">
				<el-form-item label="快递公司" prop="express_company">
					<el-select v-model="shipForm.express_company" placeholder="请选择">
						<el-option v-for="(item,index) in express_company_options" :key="index" :label="item.name" :value="item.name">
						</el-option>
					</el-select>
				</el-form-item>

				<!-- 快递单号 -->
				<el-form-item label="快递单号" prop="express_no">
					<el-input type="text" v-model="shipForm.express_no"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="shipModal = false">取 消</el-button>
				<el-button type="primary" @click="shipSubmit">确 定</el-button>
			</span>
		</el-dialog>

		<!-- 导出数据 弹窗 -->
		<el-dialog title="导出数据" :visible.sync="exportModal" width="40%">
			<el-form :model="exportForm" ref="ruleForm">

				<!-- 订单类型 -->
				<el-form-item label="订单类型" prop="tab">
					<el-select v-model="exportForm.tab" placeholder="请选择">
						<el-option v-for="(item,index) in tabbars" :key="index" :label="item.name" :value="item.key">
						</el-option>
					</el-select>
				</el-form-item>

				<!-- 时间范围 -->
				<el-form-item label="时间范围" prop="time">
					<el-date-picker size="small" v-model="exportForm.time" type="daterange" range-separator="至" start-placeholder="开始日期"
					 end-placeholder="结束日期" value-format="yyyy-MM-dd">
					</el-date-picker>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="exportModal = false">取 消</el-button>
				<el-button type="primary" @click="exportExcel">确 定</el-button>
			</span>
		</el-dialog>

		<!-- 订单详情 弹窗 -->
		<el-dialog fullscreen title="订单详情" :visible.sync="infoModel" @close="infoModel = false">

			<!-- 订单信息 -->
			<el-card v-if="info" class="box-card" shadow="never" style="margin-top: -30px;">
				<div slot="header" class="clearfix">
					<span style="font-weight: bold;">订单信息</span>
				</div>
				<el-form ref="form" label-position="left" label-width="80px" style="margin-bottom: -20px;">
					<el-form-item label="订单号">
						{{ info.no }}
					</el-form-item>
					<el-form-item v-if="info.payment_method" label="付款方式">
						{{ info.payment_method }}
					</el-form-item>
					<el-form-item v-if="info.paid_time" label="付款时间">
						{{ info.paid_time }}
					</el-form-item>
					<el-form-item label="创建时间">
						{{ info.create_time }}
					</el-form-item>
				</el-form>
			</el-card>

			<!-- 发货信息 -->
			<el-card v-if="info.ship_data" class="box-card" shadow="never">
				<div slot="header" class="clearfix">
					<span style="font-weight: bold;">发货信息</span>
				</div>
				<el-form ref="form" label-position="left" label-width="80px" style="margin-bottom: -20px;">
					<el-form-item label="物流公司">
						{{ info.ship_data.express_company }}
					</el-form-item>
					<el-form-item label="运单号">
						{{ info.ship_data.express_no }}
					</el-form-item>
					<el-form-item label="发货时间">
						{{ info.ship_data.express_time }}
					</el-form-item>
				</el-form>
			</el-card>

			<!-- 商品信息 -->
			<el-card class="box-card" shadow="never">
				<div slot="header" class="clearfix">
					<span style="font-weight: bold;">商品信息</span>
				</div>
				<div class="order-item d-flex" v-for="(item,index) in info.order_items" :key="index">
					<div style="flex-shrink: 0;">
						<img :src="item.goods_item ? item.goods_item.cover : ''" style="width: 64px;height: 64px;" />
					</div>
					<div style="margin-left: 20px;">
						<p>{{ item.goods_item ? item.goods_item.title : '商品已被删除' }}</p>
						<p v-if="item.skus_type == 1">
							{{ item.sku }}
						</p>
						<p>
							<span class="mr-2 text-danger">￥{{ item.price }}</span>
							<span class="attr">x{{ item.num }}</span>
						</p>
					</div>
				</div>
				<el-form ref="form" label-position="left" label-width="80px" style="margin-bottom: -20px;">
					<el-form-item label="成交价">
						<span style="color: red;font-size: 14px;font-weight: bold;">￥{{ info.total_price }}</span>
					</el-form-item>
				</el-form>
			</el-card>

			<!-- 收货信息 -->
			<el-card class="box-card" shadow="never" v-if="info.address">
				<div slot="header" class="clearfix">
					<span style="font-weight: bold;">收货信息</span>
				</div>
				<el-form ref="form" label-position="left" label-width="80px" style="margin-bottom: -20px;">
					<el-form-item label="收货人">
						{{ info.address.name }}
					</el-form-item>
					<el-form-item label="联系方式">
						{{ info.address.phone }}
					</el-form-item>
					<el-form-item label="收货地址">
						{{ info.address.province + info.address.city + info.address.district + info.address.address }}
					</el-form-item>
				</el-form>
			</el-card>

			<!-- 退款消息 -->
			<el-card class="box-card" shadow="never" v-if="info.refund_status != 'pending'">
				<div slot="header" class="clearfix">
					<span style="font-weight: bold;">退款消息</span>
					<el-button disabled style="float: right; padding: 3px 0" type="text">{{ info.refund_status | formatRefundStatus }}</el-button>
				</div>
				<el-form ref="form" label-position="left" label-width="80px" style="margin-bottom: -20px;">
					<el-form-item v-if="info.refund_data" label="退货理由">
						{{ info.refund_data.reason }}
					</el-form-item>
					<el-form-item v-if="info.refund_refuse" label="拒绝理由">
						{{ info.refund_refuse }}
					</el-form-item>
					<el-form-item v-if="info.refund_refuse" label="商家拒绝理由">
						{{ info.refund_refuse.refund_disagree_reason }}
					</el-form-item>
				</el-form>
			</el-card>

			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="infoModel = false">确 定</el-button>
			</div>
		</el-dialog>

	</div>
</template>

<script>
	import buttonSearch from "@/components/common/button-search.vue" // 引入 按钮、搜索、高级搜索组件
	import common from "@/common/mixins/common.js" // 引入 全局公共样式
	export default {
		inject: ['layout'],
		mixins: [common], // 引入mixins里的common.js
		// 引入注册
		components: {
			buttonSearch
		},
		filters: {
			formatRefundStatus(v) {
				let rs = {
					applied: "申请退款，等待处理",
					processing: "退款中",
					success: "退款成功"
				}
				return rs[v]
			},
		},
		data() {
			return {
				preUrl: "orderitem", //api
				tabIndex: 0,
				tabbars: [
					{ name:"全部",key:"all"},
					{ name:"待付款",key:"nopay" },
					{ name:"待发货",key:"noship"},
					{ name:"已发货",key:"shiped" },
					{ name:"已收货",key:"received" },
					{ name:"已完成",key:"finish" },
					{ name:"已关闭",key:"closed" },
					{ name:"退款中",key:"refunding" },
				],

				form: {
					no: "", //订单编号
					time: "", //下单时间
					username: "", //收货人
					phone: "", //手机号
				},
				tableData: [],
				shipModal: false,
				shipForm: {
					express_company: "",
					express_no: ""
				},
				shipId: 0, //记录订单id
				express_company_options: [],

				exportModal: false,
				exportForm: {
					tab: "",
					time: ""
				},

				infoModel: false,
				info: false,
			}
		},
		// 计算属性
		computed: {
			tab() {
				return this.tabbars[this.tabIndex].key
			},
			params() { //api拼接
				let str = ''
				for (let key in this.form) { //拿到每一个key值
					let val = this.form[key]
					if (val) { //如果val有值，才进行
						if (Array.isArray(val)) {
							str += `&starttime=${val[0]}`
							str += `&endtime=${val[1]}`
						} else {
							str += `&${key}=${this.form[key]}`
						}
					}
				}
				return str
			}
		},
		// 生命周期
		created() {
			this.axios.get('/admin/express_company/1?limit=50', { //快递公司api
				token: true
			}).then(res => {
				// console.log(res)
				let data = res.data.data
				this.express_company_options = data.list
			})
		},
		// 生命周期监听页面
		methods: {
			deleteAll(){
				this.axios.post('/admin/orderitem/delete_all', { //删除商品/恢复商品api（restore、destroy区别 删除/回复）
					ids: this.ids
				}, {
					token: true
				}).then(res => {
					this.$message({
						type: "success",
						message: "操作成功"
					})
					this.getList()
					this.hideLoading()
				}).catch(err => {
					this.hideLoading()
				})
			},
			handleRefund(id, agree = 0) {
				this[agree ? '$confirm' : '$prompt'](agree ? '是否要同意该订单退款?' : '请输入拒绝的理由', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
				}).then(({
					value
				}) => {
					this.layout.showLoading()
					let d = {
						agree
					}
					if (!agree) {
						d.disagree_reason = value
					}
					this.axios.post(`/admin/order/${id}/handle_refund`, d, {
						token: true
					}).then(res => {
						this.getList()
						this.$message.success('操作成功');
					}).catch(err => {
						this.$message.error('操作失败')
					}).finally(() => {
						this.layout.hideLoading()
					})
				})

			},
			// 导出数据
			exportExcel() {
				if (!this.exportForm.tab) {
					return this.$message({
						type: "error",
						message: "订单类型不能为空"
					})
				}
				let url = `/admin/order/excelexport?tab=${this.exportForm.tab}` //导出数据api
				let params = ''
				let val = this.exportForm.time
				if (val && Array.isArray(val)) {
					params += `&starttime=${val[0]}`
					params += `&endtime=${val[1]}`
				}
				this.layout.showLoading()
				this.axios.post(url + params, {}, {
					token: true,
					responseType: 'blob'
				}).then(res => {
					this.exportModal = false
					if (res.status == 200) { //导出下载数据表格
						let url = window.URL.createObjectURL(new Blob([res.data]))
						let link = document.createElement('a')
						link.style.display = 'none'
						link.href = url
						let filename = new Date().getTime() + '.xlsx';
						link.setAttribute('download', filename)
						document.body.appendChild(link)
						link.click()
					}
					this.layout.hideLoading()
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			// 获取请求列表分页url
			getListUrl() {
				return `/admin/${this.preUrl}/${this.page.current}?limit=${this.page.size}&tab=${this.tab}${this.params}`
			},
			// 处理获取列表结果
			getListResult(e) {
				this.tableData = e.list
				console.log(this.tableData);
			},
			// 加载数据
			handleClick(tab, event) {
				this.getList()
			},
			// 清空 高级搜索筛选条件
			clearSearch() {
				this.form = {
					no: "", //订单编号
					time: "", //下单时间
					username: "", //收货人
					phone: "", //手机号
				}
			},
			// 搜索事件
			searchEvent(e = false) {
				// 简单搜索
				if (typeof e === 'string') {
					this.form.no = e
					this.getList()
					return console.log('简单搜索', e);
				}
				// 高级搜索
				this.getList()
			},
			// 订单发货
			ship(item) {
				this.shipId = item.id //赋值订单id
				this.shipModal = true //点击事件打开弹窗
				console.log(this.shipId)
			},
			// 发货提交事件
			shipSubmit() {
				console.log(this.getList())
				this.layout.showLoading()
				this.axios.post('/admin/orderitem/' + this.shipId + '/ship', this.shipForm, { //订单发货api
					token: true,
				}).then(res => {
					this.shipModal = false
					this.$message({
						type: "success",
						message: "发货成功"
					})
					this.getList()
					
					this.layout.hideLoading()
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			openInfoModal(row) {
				this.info = row

				this.info.order_items = row.order_items.map(v => {
					if (v.skus_type == 1) {
						let s = []
						for (let k in v.goods_skus.skus) {
							s.push(v.goods_skus.skus[k].value)
						}
						v.sku = s.join(',')
					}
					return v
				})
				// console.log(v.skus_type);
				this.infoModel = true
			},

		}
	}
</script>

<style>
</style>
